import React, { Component } from 'react'
import Landing from '../../../../components/case-studies/Landing'
import AboutProject from '../../../../components/case-studies/AboutProject'
import ProjectShortDescription from '../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../components/case-studies/FontShowcase'
import Footer from '../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../components/reveal'
import MobileCarousel from '../../../../components/case-studies/MobileCarousel'
import ProjectImageLightBox from '../../../../components/case-studies/ProjectImageLightBox'

class Biutimi extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter
    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Vytvorenie mobilnej aplikácie Biutimi'}
          description={'Prípadová štúdia'}
          description2="Moderná aplikácia spájajúca vizážistky s ich klientkami. Tvorba loga, brandingu, UX dizajnu a app development."
          text={'Vízia tohto projektu bola uľahčiť ženám, ktoré potrebujú líčenie na svadbu, ples, oslavu, fotenie či inú príležitosť, nájsť profesionálnu vizážistku v blízkom i vzdialenom okolí, pomocou pár klikov.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/biutimi-branding' },
            { text: 'ux & app design' },
            { text: 'microsite', link: '/nase-prace/webstranky/microsite/biutimi-landing-page' },
            { text: 'mobilná aplikácia (Android, iOS)', link: '/nase-prace/aplikacie/biutimi' },
          ]}
          noShadow={true}
        />

        <AboutProject
          title={'Vývoj mobilnej aplikácie od A po Z'}
          text={'Intuitívna a funkčná appka si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Klientka nás oslovila so zadaním na vytvorenie <a class="blue-link" href="/tvorba-mobilnych-aplikacii">modernej aplikácie</a> spolu s prezentačným webom, ktoré ženám uľahčia hľadanie profesionálnych vizážistiek na príležitostné líčenie. Najskôr sme si s klientkou prešli všetky požiadavky, aby sme <b>pochopili jej očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Cieľom bola tvorba Android a iOS aplikácie, vďaka ktorej nájde nežné pohlavie všetky vizážistické služby na jednom mieste. Dôraz sme dali na intuitívnosť, moderný a pritom elegantný <a class="blue-link" href="/ux-ui-dizajn">dizajn aplikácie</a> a zároveň najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientkou diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa navrhli <a class="blue-link" href="/tvorba-loga">varianty loga</a> a celá <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme nakreslili wireframy (rozloženia stránok) a finálny dizajn aplikácie. Pravidelne sme klientke prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením appky optimalizujeme, vylaďujeme detailne responzívnosť a všetko finálne testujeme. Odovzdaním spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Samozrejmosťou pre programovanie Android, iOS či multiplatformových appiek je <b>spoľahlivý servis a optimalizácia</b>, aby appky išli s dobou.',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientkou sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4 cs-logos'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#74489d', tintColorCode: '#906db1', nameColor: '#FFFFFF', name: 'Levanduľová tmavá' },
          { code: '#4e296b', tintColorCode: '#715489', nameColor: '#FFFFFF', name: 'Purpurová' },
          { code: '#2b2c3e', tintColorCode: '#555665', nameColor: '#FFFFFF', name: 'Antracitová' },
          { code: '#ffffff', tintColorCode: '#ffffff', nameColor: '#6B7B8A', name: 'Biela' },
        ]} />

        <FontShowcase
          fontName={'Quicksand'}
          text={'Quicksand je displejové bezpätkové písmo so zaoblenými koncami. Projekt bol iniciovaný Andrewom Paglinawanom v roku 2008 použitím geometrických tvarov ako základného jadra. Bolo navrhnuté pre displeje, ale je dostatočne použiteľné aj na aplikovanie v malých mierkach. V roku 2016, v spolupráci s Andrewom, bolo značne zrevidované Thomasom Jockinom s cieľom ešte vylepšiť jeho kvalitu.'}
          isFullWidth={false}
          titleSize={'4em'}
          fontExampleSvg={'/images/svg/fonts/quicksand.svg'}
        />


        <Reveal>
          <div className={'short-description-center'}>
            <h2>Na detailoch záleží</h2>
            <p className="description">Precíznosť v každej maličkosti</p>
            <p className="description-smaller">Dokonalosť spočíva v detailoch. Na prvý pohľad jednoduchá tvorba loga ukrýva oveľa viac, ako sa zdá. Držať sa minimalizmu a pritom docieliť estetickú príťažlivosť – to je výzva pre každé grafické oddelenie.</p>
          </div>
        </Reveal>


        <Reveal>
          <div className={'container p-0'}>
            <Img className={'mb-5 mt-big'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>


        {/*<div className={'dark-section'}>
          <Reveal>
            <div className={'container wrapper'}>
              <div className={'row'}>
                <div className={'col-md-7'}>
                  <h2 className={'cs-title'}>Mobilná verzia</h2>
                  <p className="description">Väčšina návštevníkov prichádza cez mobilné zariadenia. Nazabúdajte na to.</p>
                  <p className="description-smaller">Responzívna verzia projektu je pre nás viac ako dôležitá. Stránky preto navrhujeme od samého začiatku tak, aby boli prehľadné a použiteľné aj na mobiloch, tabletoch či iných rozlíšeniach. Len vďaka tomu zabezpečíte svojim návštevníkom plnohodnotné UX (user experience), a zvýšite svoju šancu, aby využili Vaše služby, alebo zakúpili Váš produkt, či vykonali inú požadovanú akciu. Mobilné verzie dôkladne testujeme a simulujeme rôzne podmienky a zariadenia, aby sme zaručili čo najlepší zážitok pri prezeraní Vášho projektu.</p>
                </div>
                <div className={'col-md-5'}>
                  <Img alt={this.constructor.name} fluid={data.icon.childImageSharp.fluid}/>
                </div>
              </div>
            </div>
          </Reveal>
        </div>*/}

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        <MobileCarousel
          title={'Dizajn mobilnej aplikácie'}
          description='Dokonalé UI a UX v každom jednom detaile.'
          smallDescription="Vytvoriť responzívnu appku do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na všetkých typoch smartfónov, tabletov a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastnú appku a chce, aby mu prinášala čo najväčší zisk."
          items={[
            data.screenTwo,
            data.screenThree,
            data.screenFour,
            data.screenFive,
            data.screenSix,
            data.screenSeven,
            data.screenEight,
            data.screenNine,
          ]}
        />


        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.biutimi.com" target='_blank' className='link-secondary'>www.biutimi.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Cario'}
          nextProjectUrl={'/nase-prace/pripadove-studie/aplikacie/cario/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
    project: markdownRemark(fields: {slug: {regex: "/biutimi/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Biutimi_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/1a.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/Biutimi_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/Biutimi_CI.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    icon: file(relativePath: { eq: "case-studies/Biutimi_icon.png" }) {
        childImageSharp {
            fluid( maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenOne: file(relativePath: { eq: "case-studies/Biutimi_screenOne.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenTwo: file(relativePath: { eq: "case-studies/Biutimi_screenTwo.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenThree: file(relativePath: { eq: "case-studies/Biutimi_screenThree.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFour: file(relativePath: { eq: "case-studies/Biutimi_screenFour.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFive: file(relativePath: { eq: "case-studies/Biutimi_screenFive.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenSix: file(relativePath: { eq: "case-studies/Biutimi_screenSix.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenSeven: file(relativePath: { eq: "case-studies/Biutimi_screenSeven.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenEight: file(relativePath: { eq: "case-studies/Biutimi_screenEight.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenNine: file(relativePath: { eq: "case-studies/Biutimi_screenNine.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Biutimi
